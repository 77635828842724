import axios, { AxiosInstance } from 'axios';
import { MatrixRequestsI, PriceI } from '@/types/widget';
import {
  ConfirationEmail,
  PaymentSetupI,
  PostVerifyI,
  ReservationI,
  ResRequestCountriesPayloadI
} from '@/types/booking';

interface ApiConstructorOptions {
  client?: never;
  widgetId: string | null;
  apiUrl: string | null;
}

export default class Api {
  client: AxiosInstance;

  widgetId: string | null;

  apiUrl: string | null;

  constructor(options: ApiConstructorOptions) {
    this.widgetId = options.widgetId;
    this.apiUrl = options.apiUrl;
    this.client = options.client
      || axios.create({
        baseURL: options.apiUrl as string,
        headers: {
          'Content-Type': 'application/json',
        },
      });
  }

  async getWidgetInfo() {
    const { data } = await this.client.get(`/v3/widgets/${this.widgetId}`);
    return data;
  }

  async getWidgetResult(option: MatrixRequestsI) {
    const { data } = await this.client.post(
      `/v3/widgets/info/${this.widgetId}`,
      option,
    );
    return data;
  }

  async getPrice(option: PriceI) {
    const { data } = await this.client.post(
      '/v3/price',
      option,
    );
    return data;
  }

  async postReservation(option: ReservationI) {
    const { data } = await this.client.post(
      '/v3/reservation',
      option,
    );
    return data;
  }

  async postConfirmationSetup(option: PaymentSetupI) {
    const { data } = await this.client.post(
      '/v3/payment/setup',
      option,
    );
    return data;
  }

  async postConfirmationVerify(option: {
    widget_id: string | number;
    gateway_config: string | undefined;
    gateway_api: string
  }) {
    const { data } = await this.client.post(
      '/v3/payment/verify',
      option,
    );
    return data;
  }

  async postConfirmationEmail(option: ConfirationEmail) {
    const { data } = await this.client.post(
      '/v3/confirmationEmail',
      option,
    );
    return data;
  }

  async postVerify(option: PostVerifyI) {
    const { data } = await this.client.post(
      '/v3/payment/verify',
      option,
    );
    return data;
  }

  async postDefer(option: ConfirationEmail) {
    const { data } = await this.client.post(
      '/v3/payment/defer',
      option,
    );
    return data;
  }

  async getResRequestCountries(option: ResRequestCountriesPayloadI) {
    const { data } = await this.client.get(
      '/v3/country',
      { params: option },
    );
    return data;
  }
}
