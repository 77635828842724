import * as yup from 'yup';
import { format } from 'date-fns';

import {
  PaymentSetupDataI,
  BookingSummaryDataI,
  ItineraryI,
} from '@/types/booking';
import {
  OptionsI, PropertiesI, RatesI, SearchWidgetI,
} from '@/types/search';
import { CaseE, ElementE } from '../types/enums';
import { CaseI } from '../types/cases';

const widgetDataJSON = localStorage.getItem('widgetData');
const widgetData = JSON.parse(widgetDataJSON ?? 'null'); // 'null'?

type QueryParamT = 'propertyid' | 'rateid' | 'checkin' | 'checkout' | 'numberofadult';

const validQueryParams = ['gsite', 'propertyid', 'rateid', 'checkin', 'checkout', 'numberofadult'];
const MAX_MATCH = validQueryParams.length;

interface QueryParamsObjectI {
  [key: string]: string;
}

export const getQueryParamsObject = () => {
  const queryString: string = window.location?.search.slice(1);
  const queryParamsArray: string[] = queryString.split('&');
  const queryParamsObject: QueryParamsObjectI = {};

  queryParamsArray.forEach((item) => {
    const [key, value] = item.split('=');
    queryParamsObject[key] = value;
  });

  return queryParamsObject;
};

export const isValidDate = (date: string) => !Number.isNaN(Date.parse(date));

export const isQueryString = () => !!window.location.search;

export const isQueryStringValid = () => {
  const q: QueryParamsObjectI = getQueryParamsObject();

  const foundMatches = Object.keys(q).reduce((count, value) => {
    if (validQueryParams.includes(value)) {
      return count + 1;
    }
    return count;
  }, 0);

  if (foundMatches < MAX_MATCH) {
    return false;
  }

  const isValidPropertyidParam: boolean = isValidPropertyid(q.propertyid);
  const isValidCheckInAndOutParam: boolean = isValidCheckInAndOut(q.checkin, q.checkout);
  const isValidNumberOfAdultsParam: boolean = isValidNumberOfAdults(q.numberofadult);
  const isValidRateIdParam: boolean = isValidRateId(q.rateid);

  return (
    isValidPropertyidParam
    && isValidCheckInAndOutParam
    && isValidNumberOfAdultsParam
    && isValidRateIdParam
  );
};

export const isValidPropertyid = (propertyid: string) => {
  const [principalId, propertyId] = [...propertyid.split('|')];

  const foundProperty: object = widgetData.properties.find(
    (item: PropertiesI) => item.principal_rr_id === principalId && item.rr_id === propertyId,
  );

  return !!foundProperty;
};

export const isValidCheckInAndOut = (checkInDate: string, checkOutDate: string) => {
  const isValidCheckInDate: boolean = isValidDate(checkInDate);
  const isValidCheckoutDate: boolean = isValidDate(checkOutDate);
  const today: string = new Date().toISOString().slice(0, 10);

  return (
    isValidCheckInDate && isValidCheckoutDate && checkInDate > today && checkOutDate > checkInDate
  );
};

export const isValidNumberOfAdults = (numberOfAdults: string) => {
  const numAdults: number = parseInt(numberOfAdults, 10);

  return !Number.isNaN(numAdults) && numAdults > 0;
};

export const isValidRateId = (rateid: string) => widgetData.properties[0].rates.some(
  (item: RatesI) => item.id === rateid,
);

export const getQueryParam = (param: QueryParamT) => {
  const q: QueryParamsObjectI = getQueryParamsObject();

  return q[param];
};

export const arrayOfNumbers = (number: number) => (
  [...Array(number)].map((e, i) => i + 1)
);

export const datesForDefaultSearchData = () => {
  if (isQueryString() && isQueryStringValid()) {
    return [new Date(getQueryParam('checkin')), new Date(getQueryParam('checkout'))];
  }

  return [new Date(), new Date(new Date().setDate(new Date().getDate() + 7))];
};

export const defaultSearchData = {
  properties: [],
  nights: 1,
  currency: 'USD - Rack',
};

export const getCurrency = (data: SearchWidgetI): OptionsI[] => {
  if (isQueryString() && isQueryStringValid()) {
    const queryRateId = getQueryParam('rateid');

    const rates = [...data.properties[0].rates];

    const foundRate = rates.find((item: RatesI) => item.id === queryRateId)!;
    const index = rates.indexOf(foundRate);
    const [selectedRate] = rates.splice(index, 1);
    rates.unshift(selectedRate);

    return rates.length
      ? rates.map(({ id, currency, name }: RatesI) => ({ value: id, label: `${currency} - ${name}` }))
      : [];
  }

  const { rates } = data.properties[0];
  return rates.length
    ? rates.map(({ id, currency, name }: RatesI) => ({ value: id, label: `${currency} - ${name}` }))
    : [];
};

export const formatMoney = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export const formatToParts = (amount: number, currency = 'USD', hasNoCents = false) => {
  const numberFormat = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
  });

  const parts = numberFormat.formatToParts(amount);

  const filteredParts = hasNoCents
    ? parts.filter((p) => p.type !== 'decimal' && p.type !== 'fraction')
    : parts;

  return filteredParts.map((p) => p.value);
};

export const formatDayOfWeek = (date: Date) => {
  return format(new Date(date), 'eee');
};

export const formatDay = (date: Date) => {
  return format(new Date(date), 'dd');
};

export const formatMonth = (date: Date) => {
  return format(new Date(date), 'MMM');
};

export const formatYear = (date: Date) => {
  return format(new Date(date), 'yyyy');
};

export const formatDateSingle = (date: Date) => {
  return format(new Date(date), 'eee dd MMM yyyy');
};

export const formatRangeDate = (date: Date[]) => {
  const fromYear = date[0].getFullYear();
  const toYear = date[1].getFullYear();
  return `${format(new Date(date[0]), `eee dd MMM ${fromYear !== toYear ? 'yyyy' : ''}`)} -
   ${format(new Date(date[1]), 'eee dd MMM yyyy')}`;
};

const cases: CaseI = {
    [ElementE.BUTTON]: CaseE.SENTENCE_CASE,
    [ElementE.TITLE]: CaseE.SENTENCE_CASE,
    [ElementE.SUBTITLE]: CaseE.SENTENCE_CASE,
    [ElementE.DROPDOWN]: CaseE.SENTENCE_CASE,
    [ElementE.LABEL]: CaseE.SENTENCE_CASE,
    [ElementE.INPUT]: CaseE.SENTENCE_CASE,
    [ElementE.PLACEHOLDER]: CaseE.SENTENCE_CASE,
};

export function changeCase(input: string | number, element: ElementE): string | number {
    if (typeof input === 'number') {
        return input;
    }

    switch (cases[element]) {
        case CaseE.SENTENCE_CASE: {
            const sentences = input.match(/[^.!?]+[.!?]+/g);
            if (sentences && sentences.length > 1) {
                return toSentenceCase(sentences);
            }

            return input.charAt(0).toUpperCase() + input.slice(1).toLowerCase();
        }

        case CaseE.UPPER_CASE:
            return input.toUpperCase();

        case CaseE.LOWER_CASE:
            return input.toLowerCase();

        case CaseE.TITLE_CASE: {
            const words = input.split(' ');
            const titleCaseWords = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());

            return titleCaseWords.join(' ');
        }

        default:
            return '';
    }
}

function toSentenceCase(sentences: string[]) {
    const correctedSentences = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < sentences.length; i++) {
        const sentence = sentences[i].trim();
        const correctedSentence = sentence.charAt(0).toUpperCase() + sentence.toLowerCase().slice(1);
        correctedSentences.push(correctedSentence);
    }

    const correctedText = correctedSentences.join(' ');
    return correctedText;
}

export const rules = yup.object({
  firstName: yup.string().trim().required('First Name is a required field'),
  lastName: yup.string().trim().required('Last Name is a required field'),
  email: yup.string().required('Email is a required field').email('Valid email address required'),
  confirm: yup.string().required('Email is a required field').oneOf([yup.ref('email')], 'Email addresses do not match'),
});

/*
  Peach doc: https://peachpayments.docs.oppwa.com/tutorials/integration-guide
  Used COPYandPAY Integration
*/
export const loadPeachForm = async (paymentDetails: PaymentSetupDataI) => {
  if (paymentDetails.gateway_api !== 'peachcard') return;
  const head = document.head || document.getElementsByTagName('head')[0];
  const body = document.getElementsByTagName('body')[0];
  const testUrl = 'https://eu-test.oppwa.com';
  const liveUrl = 'https://eu-prod.oppwa.com';
  const url = paymentDetails.dev_mode ? testUrl : liveUrl;
  const scriptSrc = `${url}/v1/paymentWidgets.js?checkoutId=${paymentDetails?.gateway_config?.checkout_id}`;

  const script = document.createElement('script');
  const scriptPeachOptions = document.createElement('script');

  script.setAttribute('async', 'true');
  script.setAttribute('src', scriptSrc);
  scriptPeachOptions.append('var wpwlOptions = { style: "card" }');
  head.append(script, scriptPeachOptions);

  if (!body) return;
  const peachContainer = document.createElement('div');
  const form = document.createElement('form');
  const formGroupButtonsSelector = 'div.wpwl-wrapper.wpwl-wrapper-submit';

  body.style.position = 'relative';

  peachContainer.setAttribute('id', 'js-peach-container');
  peachContainer.style.cssText = `
    z-index: 9999;
    position: absolute;
    top: calc(50% - 100px);
    width: 100%;
    height: 200px;
  `;

  form.setAttribute('action', `${paymentDetails?.gateway_config?.return_url}`);
  form.setAttribute('data-brands', 'VISA MASTER AMEX');
  form.classList.add('peachpayments', 'paymentWidgets');

  peachContainer.append(form);
  body.append(peachContainer);

  // Need to wait until when form was loading and
  // add a "Cancel" buttton with custom event
  setTimeout(() => {
    const peachLoaded = setInterval(() => {
      if (!document.querySelector(formGroupButtonsSelector)) return;
        (document.querySelector(formGroupButtonsSelector) as any).innerHTML
          += '<button class="wpwl-button wpwl-button-error" type="button" aria-label="Cancel"'
          + 'onclick="window.cancelPeachPaymentGateway();">Cancel</button>';
        clearInterval(peachLoaded);
    }, 500);
  }, 1000);
};

export const formattedItinerariesList = (bookingSummaryData: BookingSummaryDataI[]) => {
  return bookingSummaryData.reduce((initialValue: ItineraryI[], { items }) => {
    return [...initialValue, ...items];
  }, []);
};

export const emailRegex = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

export const getContactUsHref = (contactData = widgetData.config_contact_email): string => {
  if (!contactData) return '';
  const mailTo = emailRegex.test(contactData) ? 'mailto:' : '';
  return `${mailTo}${contactData}`;
};
